/* variables
=================================================================================*/
.editorDialogs .modal, .note-editor .modal {
  background-color: #212121;
  color: #fafafa;
  z-index: 1057 !important;
  backface-visibility: hidden; }
  .editorDialogs .modal .input-field input:not([readonly]), .editorDialogs .modal .input-field input.datepicker, .note-editor .modal .input-field input:not([readonly]), .note-editor .modal .input-field input.datepicker {
    border-color: #fafafa !important; }
  .editorDialogs .modal .input-field input:focus:not([readonly]), .editorDialogs .modal .input-field input.datepicker:focus, .editorDialogs .modal textarea.materialize-textarea:focus:not([readonly]), .note-editor .modal .input-field input:focus:not([readonly]), .note-editor .modal .input-field input.datepicker:focus, .note-editor .modal textarea.materialize-textarea:focus:not([readonly]) {
    box-shadow: 0 1px 0 0 #7298E3 !important;
    border-color: #7298E3 !important; }
  .editorDialogs .modal label, .editorDialogs .modal .input-field input:not([readonly]) + label, .editorDialogs .modal .input-field input.datepicker + label, .editorDialogs .modal .input-field .prefix, .editorDialogs .modal .note-editor + label, .note-editor .modal label, .note-editor .modal .input-field input:not([readonly]) + label, .note-editor .modal .input-field input.datepicker + label, .note-editor .modal .input-field .prefix, .note-editor .modal .note-editor + label {
    color: #fafafa !important; }
  .editorDialogs .modal .input-field input:focus:not([readonly]) + label, .editorDialogs .modal .input-field input.datepicker:focus + label, .editorDialogs .modal .input-field .prefix.active, .editorDialogs .modal textarea.materialize-textarea:focus:not([readonly]) + label, .note-editor .modal .input-field input:focus:not([readonly]) + label, .note-editor .modal .input-field input.datepicker:focus + label, .note-editor .modal .input-field .prefix.active, .note-editor .modal textarea.materialize-textarea:focus:not([readonly]) + label {
    color: #7298E3 !important; }
  .editorDialogs .modal .btn, .editorDialogs .modal .btn-large, .note-editor .modal .btn, .note-editor .modal .btn-large {
    background-color: #B71C1C; }
  .editorDialogs .modal .btn:hover, .editorDialogs .modal .btn-large:hover, .note-editor .modal .btn:hover, .note-editor .modal .btn-large:hover {
    background-color: #de2828 !important; }
  .editorDialogs .modal .btn.disabled, .editorDialogs .modal .disabled.btn-large, .note-editor .modal .btn.disabled, .note-editor .modal .disabled.btn-large {
    background-color: #751212 !important; }
  .editorDialogs .modal .modal-footer, .note-editor .modal .modal-footer {
    background-color: #080808; }
    .editorDialogs .modal .modal-footer .btnClose, .note-editor .modal .modal-footer .btnClose {
      margin-right: 15px;
      background-color: #098ddf; }
    .editorDialogs .modal .modal-footer .btnClose:hover, .note-editor .modal .modal-footer .btnClose:hover {
      background-color: #098ddf !important; }
  .editorDialogs .modal .canvasContainerEmpty, .note-editor .modal .canvasContainerEmpty {
    border: solid 5px #7298E3; }

.note-editor .note-editable::-webkit-scrollbar, .editorDialogs .modal-content::-webkit-scrollbar, .note-editor .note-color-palette::-webkit-scrollbar, .note-editor .note-codable::-webkit-scrollbar, .modal.modal-fixed-footer .modal-content::-webkit-scrollbar {
  width: 17px !important; }
.note-editor .note-editable::-webkit-scrollbar-track, .editorDialogs .modal-content::-webkit-scrollbar-track, .note-editor .note-color-palette::-webkit-scrollbar-track, .note-editor .note-codable::-webkit-scrollbar-track, .modal.modal-fixed-footer .modal-content::-webkit-scrollbar-track {
  background-color: #212121 !important; }
.note-editor .note-editable::-webkit-scrollbar-thumb, .editorDialogs .modal-content::-webkit-scrollbar-thumb, .note-editor .note-color-palette::-webkit-scrollbar-thumb, .note-editor .note-codable::-webkit-scrollbar-thumb, .modal.modal-fixed-footer .modal-content::-webkit-scrollbar-thumb {
  background-color: #545454 !important; }
.note-editor .note-editable::-webkit-scrollbar-thumb:hover, .editorDialogs .modal-content::-webkit-scrollbar-thumb:hover, .note-editor .note-color-palette::-webkit-scrollbar-thumb:hover, .note-editor .note-codable::-webkit-scrollbar-thumb:hover, .modal.modal-fixed-footer .modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #6e6e6e !important; }

.note-editor {
  position: relative;
  border-left: 3px solid #212121;
  border-bottom: 3px solid #212121;
  border-right: 3px solid #212121; }
  .note-editor .img-circle {
    border-radius: 50%; }
  .note-editor .img-rounded {
    border-radius: 5%; }
  .note-editor .img-thumbnail {
    border: solid 5px #212121;
    height: 200px; }
  .note-editor .img-bordered {
    border: solid 5px #212121; }
  .note-editor .btn:hover, .note-editor .btn-large:hover {
    background-color: #3b3b3b !important; }
  .note-editor .btn.active, .note-editor .active.btn-large {
    background-color: #7298E3; }
  .note-editor .note-editable ul li {
    list-style-type: square !important;
    display: list-item;
    list-style-position: inside; }
  .note-editor .note-dialog > div {
    display: none; }
  .note-editor .note-dialog .form-group {
    margin-right: 0;
    margin-left: 0; }
  .note-editor .note-dialog .note-modal-form {
    margin: 0; }
  .note-editor .note-dialog .note-image-dialog .note-dropzone {
    min-height: 100px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 4;
    color: lightgray;
    text-align: center;
    border: 4px dashed lightgray; }
  .note-editor .transparent {
    opacity: 0; }
  .note-editor .note-resizebar {
    background-color: #212121;
    width: 100%;
    height: 13px;
    cursor: ns-resize;
    padding-top: 1px; }
    .note-editor .note-resizebar .note-icon-bar {
      width: 20px;
      margin: 2px auto;
      border-top: 2px solid #545454; }
  .note-editor .note-toolbar {
    position: relative;
    color: #fafafa;
    background-color: #212121;
    margin: 0;
    z-index: 1052; }
    .note-editor .note-toolbar ul {
      padding: 0; }
    .note-editor .note-toolbar .btn.disabled, .note-editor .note-toolbar .disabled.btn-large, .note-editor .note-toolbar button.disabled {
      display: none; }
    .note-editor .note-toolbar .dropdown {
      cursor: pointer; }
    .note-editor .note-toolbar .note-current-fontname {
      min-width: 134px;
      display: inline-block;
      text-align: left; }
  .note-editor .note-handle .note-control-selection {
    position: absolute;
    display: none;
    border: 2px solid #212121; }
    .note-editor .note-handle .note-control-selection .note-control-selection-bg {
      width: 100%;
      height: 100%;
      z-index: 3;
      background-color: rgba(117, 117, 117, 0.3); }
    .note-editor .note-handle .note-control-selection > div {
      position: absolute; }
    .note-editor .note-handle .note-control-selection .note-control-handle {
      width: 7px;
      height: 7px;
      border: 1px solid black; }
    .note-editor .note-handle .note-control-selection .note-control-holder {
      width: 7px;
      height: 7px;
      border: 1px solid black; }
    .note-editor .note-handle .note-control-selection .note-control-sizing {
      width: 15px;
      height: 15px;
      background-color: #212121;
      z-index: 5;
      cursor: se-resize; }
    .note-editor .note-handle .note-control-selection .note-control-nw {
      top: -5px;
      left: -5px;
      border-right: 0;
      border-bottom: 0; }
    .note-editor .note-handle .note-control-selection .note-control-ne {
      top: -5px;
      right: -5px;
      border-bottom: 0;
      border-left: none; }
    .note-editor .note-handle .note-control-selection .note-control-sw {
      bottom: -5px;
      left: -5px;
      border-top: 0;
      border-right: 0; }
    .note-editor .note-handle .note-control-selection .note-control-se {
      right: -5px;
      bottom: -5px; }
    .note-editor .note-handle .note-control-selection .note-control-selection-info {
      right: 0;
      bottom: 0;
      padding: 5px;
      margin: 17px;
      font-size: 15px;
      color: #fafafa;
      background-color: #212121;
      z-index: 5; }

.note-dialog .note-help-dialog {
  color: #212121; }
  .note-dialog .note-help-dialog h4 {
    color: #fafafa; }
  .note-dialog .note-help-dialog thead {
    background-color: #7298E3; }
  .note-dialog .note-help-dialog tbody {
    background-color: #e0e0e0; }

.note-editor .btn-group, .popover .btn-group {
  display: inline-block;
  margin-right: 10px;
  position: relative; }
  .note-editor .btn-group ul, .popover .btn-group ul {
    padding: 0; }
  .note-editor .btn-group .closeLeft, .popover .btn-group .closeLeft {
    padding-right: 0 !important;
    margin-right: 0 !important; }
    .note-editor .btn-group .closeLeft i, .popover .btn-group .closeLeft i {
      margin-right: 0 !important; }
  .note-editor .btn-group i.left, .popover .btn-group i.left {
    margin-right: 5px; }
.note-editor .btn, .note-editor .btn-large, .popover .btn, .popover .btn-large {
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0 9px !important;
  background-color: #212121; }
.note-editor .btnSecond, .popover .btnSecond {
  background-color: #B71C1C !important; }
.note-editor .btnThird, .popover .btnThird {
  background-color: #098ddf !important; }
.note-editor note-toolbar button, .note-editor button, .popover note-toolbar button, .popover button {
  background-color: #212121;
  border: none;
  height: 36px;
  text-transform: none; }
.note-editor [type="checkbox"]:checked + label:before, .note-editor [type="checkbox"]:checked + label:before, .popover [type="checkbox"]:checked + label:before, .popover [type="checkbox"]:checked + label:before {
  border-right-color: #B71C1C !important;
  border-bottom-color: #B71C1C !important; }
.note-editor .note-palette-title, .popover .note-palette-title {
  padding: 0 !important; }
.note-editor .colorName, .popover .colorName {
  display: inline-block;
  color: #fafafa; }
  @media only screen and (max-width: 600px) {
    .note-editor .colorName, .popover .colorName {
      display: none; } }
.note-editor .note-color-palette, .popover .note-color-palette {
  line-height: 10px;
  border: solid 3px #212121;
  padding: 0 !important;
  overflow-x: scroll;
  overflow-y: hidden; }
  .note-editor .note-color-palette .note-color-row, .popover .note-color-palette .note-color-row {
    padding: 0 !important;
    min-width: 300px; }
  .note-editor .note-color-palette button.note-color-btn, .popover .note-color-palette button.note-color-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0; }
  .note-editor .note-color-palette .note-color-btn:hover:after, .popover .note-color-palette .note-color-btn:hover:after {
    position: absolute;
    width: 30px;
    height: 30px;
    content: "";
    background-color: inherit;
    margin-top: -15px;
    margin-left: -15px; }
.note-editor .note-dimension-picker, .popover .note-dimension-picker {
  overflow: hidden; }
.note-editor .largeDropdown, .popover .largeDropdown {
  width: 290px; }
.note-editor .dropdown-menu, .popover .dropdown-menu {
  z-index: 1033; }
  .note-editor .dropdown-menu.note-check, .popover .dropdown-menu.note-check {
    min-width: 80px; }
  .note-editor .dropdown-menu label, .popover .dropdown-menu label {
    color: #fafafa !important; }
.note-editor ul.dropdown-menu, .popover ul.dropdown-menu {
  position: absolute;
  top: 20px;
  background-color: #3b3b3b;
  border-left: 3px solid #212121;
  border-bottom: 3px solid #212121;
  border-right: 3px solid #212121; }
  .note-editor ul.dropdown-menu#colors, .popover ul.dropdown-menu#colors {
    width: 342px; }
    .note-editor ul.dropdown-menu#colors .indicator, .popover ul.dropdown-menu#colors .indicator {
      width: 50%;
      left: 0; }
  .note-editor ul.dropdown-menu .colorTable, .popover ul.dropdown-menu .colorTable {
    padding: 3px 0; }
  .note-editor ul.dropdown-menu .tabs, .popover ul.dropdown-menu .tabs {
    background-color: #212121; }
    .note-editor ul.dropdown-menu .tabs:hover, .popover ul.dropdown-menu .tabs:hover {
      background-color: #212121; }
    .note-editor ul.dropdown-menu .tabs .tab a, .note-editor ul.dropdown-menu .tabs .tab a:hover, .popover ul.dropdown-menu .tabs .tab a, .popover ul.dropdown-menu .tabs .tab a:hover {
      color: #FF1744; }
    .note-editor ul.dropdown-menu .tabs .indicator, .popover ul.dropdown-menu .tabs .indicator {
      background-color: #FF1744; }
  .note-editor ul.dropdown-menu li, .popover ul.dropdown-menu li {
    list-style-type: none;
    padding: 0 !important; }
    .note-editor ul.dropdown-menu li div, .popover ul.dropdown-menu li div {
      padding: 3px 15px;
      cursor: pointer; }

.note-popover .popover {
  position: absolute;
  max-width: none;
  color: #fafafa; }
  .note-popover .popover .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #212121 transparent; }
  .note-popover .popover .popover-content {
    background-color: #212121; }
    .note-popover .popover .popover-content > a {
      margin-left: 12px; }
    .note-popover .popover .popover-content a {
      display: inline-block;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle; }
    .note-popover .popover .popover-content .arrow {
      left: 20px; }
    .note-popover .popover .popover-content .btn-group {
      display: inline-block; }
      .note-popover .popover .popover-content .btn-group .btn, .note-popover .popover .popover-content .btn-group .btn-large {
        border-radius: 0 !important;
        box-shadow: none !important;
        padding: 0 9px !important;
        background-color: #212121; }

.note-popover .popover .popover-content .note-para .dropdown-menu, .note-toolbar .note-para .dropdown-menu {
  min-width: 172px;
  padding: 5px; }

.note-popover .popover .popover-content .note-para .dropdown-menu > div:first-child, .note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 5px; }

.note-editor .note-dropzone {
  position: absolute;
  z-index: 100;
  display: none;
  color: #87cefa;
  background-color: white;
  border: 2px dashed #87cefa;
  opacity: .95;
  pointer-event: none; }

.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle; }

.note-editor .note-dropzone.hover {
  color: #098ddf;
  border: 2px dashed #098ddf; }

.note-editor.dragover .note-dropzone {
  display: table; }

.note-editor.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2021;
  width: 100%; }

.note-editor.fullscreen .note-editable {
  background-color: white; }

.note-editor.fullscreen .note-resizebar {
  display: none; }

.note-editor.codeview .note-editable {
  display: none; }

.note-editor.codeview .note-codable {
  display: block; }

.note-editor .note-statusbar {
  background-color: #f5f5f5; }

.note-editor .note-editable[contenteditable=true]:empty:not(:focus):before {
  color: #a9a9a9;
  content: attr(data-placeholder); }

.note-editor .note-editable {
  padding: 10px;
  overflow: auto;
  outline: 0; }

.note-editor .note-editable[contenteditable="false"] {
  background-color: #e5e5e5; }

.note-editor .note-codable {
  display: none;
  width: 100%;
  padding: 10px;
  margin-bottom: 0;
  font-family: Menlo, Monaco, monospace, sans-serif;
  font-size: 14px;
  color: #ccc;
  background-color: #222;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  resize: none; }

.note-air-editor {
  outline: 0; }

.note-popover .popover .popover-content,
.note-toolbar {
  padding: 0;
  margin: 0; }

.note-popover .popover .popover-content > .btn-group,
.note-toolbar > .btn-group {
  margin-top: 0;
  margin-right: 5px;
  margin-left: 0; }

.note-popover .popover .popover-content .btn-group .note-table,
.note-toolbar .btn-group .note-table {
  min-width: 0;
  padding: 5px; }

.note-popover .popover .popover-content .btn-group .note-table .note-dimension-picker,
.note-toolbar .btn-group .note-table .note-dimension-picker {
  font-size: 18px; }

.note-popover .popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher,
.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  position: absolute !important;
  z-index: 3;
  width: 260px;
  height: 260px;
  cursor: pointer; }

.note-popover .popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted,
.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  position: relative !important;
  z-index: 1;
  width: 312px;
  height: 130px;
  background-size: 26px 26px;
  background-image: repeating-linear-gradient(0deg, #3b3b3b, #3b3b3b 4px, transparent 4px, transparent 26px), repeating-linear-gradient(-90deg, transparent, transparent 4px, #fff 4px, #fff 26px); }

.note-popover .popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted,
.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  position: absolute !important;
  z-index: 2;
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
  background-image: repeating-linear-gradient(0deg, #3b3b3b, #3b3b3b 4px, transparent 4px, transparent 26px), repeating-linear-gradient(-90deg, transparent, transparent 4px, #7298E3 4px, #7298E3 26px); }

.note-popover .popover .popover-content .note-style h1,
.note-toolbar .note-style h1,
.note-popover .popover .popover-content .note-style h2,
.note-toolbar .note-style h2,
.note-popover .popover .popover-content .note-style h3,
.note-toolbar .note-style h3,
.note-popover .popover .popover-content .note-style h4,
.note-toolbar .note-style h4,
.note-popover .popover .popover-content .note-style h5,
.note-toolbar .note-style h5,
.note-popover .popover .popover-content .note-style h6,
.note-toolbar .note-style h6,
.note-popover .popover .popover-content .note-style blockquote,
.note-toolbar .note-style blockquote {
  margin: 0; }

.note-popover .popover .popover-content .note-color .dropdown-toggle,
.note-toolbar .note-color .dropdown-toggle {
  width: 20px;
  padding-left: 5px; }

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group,
.note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 0; }

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group:first-child,
.note-toolbar .note-color .dropdown-menu .btn-group:first-child {
  margin: 0 5px; }

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title,
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  margin: 2px 7px;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #eee; }

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  padding: 0 3px;
  margin: 3px;
  font-size: 11px;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-row,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-row {
  height: 20px; }

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset:hover,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset:hover {
  background: #eee; }

/*.note-popover .popover .popover-content .dropdown-menu,
.note-toolbar .dropdown-menu {
    min-width: 90px
}*/
.note-popover .popover .popover-content .dropdown-menu.right,
.note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto; }

.note-popover .popover .popover-content .dropdown-menu.right::before,
.note-toolbar .dropdown-menu.right::before {
  right: 9px;
  left: auto !important; }

.note-popover .popover .popover-content .dropdown-menu.right::after,
.note-toolbar .dropdown-menu.right::after {
  right: 10px;
  left: auto !important; }

.note-popover .popover .popover-content .dropdown-menu.note-check li a i,
.note-toolbar .dropdown-menu.note-check li a i {
  color: deepskyblue;
  visibility: hidden; }

.note-popover .popover .popover-content .dropdown-menu.note-check li a.checked i,
.note-toolbar .dropdown-menu.note-check li a.checked i {
  visibility: visible; }

.note-popover .popover .popover-content .note-fontsize-10,
.note-toolbar .note-fontsize-10 {
  font-size: 10px; }

/*# sourceMappingURL=materialNote.css.map */
